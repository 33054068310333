import React, { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { MdOutlineTranslate } from "react-icons/md";

import LanguageDrop from "./LanguageDrop";
import pharmActProfile from '../../../assets/images/pharmActProfile.png'
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from "react-redux";
import { VscThreeBars } from "react-icons/vsc";

import { SubcribeToPremiumPlan } from "../../../features/Dashoard/slices/subscriptionActions";
const Navbar = ({isActive,handleSidebar}) => {
  const [langDrop, setLangDrop] = useState(false);
  const [language, setLanguage] = useState("English (US)");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const dispach=useDispatch();
  const navigate = useNavigate();
  const isPremium = useSelector((state) => state.subscription.isPremium);
  console.log(isPremium)
  const subscriptionData = {
    subscriptionType: "Premium",
    healthOrgService: "64b5f3e3a44d7c5b2f10d123",
    startDate: "2024-08-10T09:30:00.000Z",
    email: "user@example.com",}
  const handleLang = () => {
    setLangDrop((prev) => !prev);
  };

  const handleChangeLang = (lang) => {
    setLanguage(lang);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleShow = () => {
    setShow(prev => !prev);
  };

  const handleLogout = () => {
    navigate('/auth/login');
  };
  const handleSubscribe = () => {
    console.log("tuqameera");
    dispach(SubcribeToPremiumPlan(subscriptionData));
  };
  return (
    <div className="w-full pl-8">
      <div className="flex items-center justify-between w-full h-[60px]">
        {/* Search box */}
        <div className="flex gap-4 ">
          {
       !isActive && 
        <VscThreeBars size={40} onClick={handleSidebar} className="text-[#3e7a6d] hover:cursor-pointer"/>
          }

        <div className="flex md:flex-row items-center w-[440px] h-[38px] rounded px-2 gap-1 border border-gray-800">
          
          <div className="w-full py-2">
            <input
              type="text"
              placeholder="Search for anything here"
              className="py-1 outline-none bg-inherit text-black text-opacity-50 text-base font-normal font-['Poppins'] w-full"
            />
          </div>
          <div className="ml-auto">
            <IoIosSearch />
          </div>
        </div>
        </div>

        <div className="flex items-center" onClick={handleLang}>
          <div className="w-[20px] h-[20px] relative flex-col justify-start items-start inline-flex">
            <MdOutlineTranslate />
          </div>
          <div className="text-gray-800 text-xs font-normal font-['Poppins'] relative">
            <div>
              <h5>{language}</h5>
            </div>
          </div>
          <div>
            {!langDrop ? (
              <RiArrowDropDownLine />
            ) : (
              <span className="pl-1">n</span>
            )}
            <LanguageDrop
              langDrop={langDrop}
              language={language}
              handleChangeLang={handleChangeLang}
              handleLang={handleLang}
            />
          </div>
        </div>
        
        <div className='relative flex items-center justify-start gap-3 my-4'>
          <div className='relative pl-4'>
            <img src={pharmActProfile} alt="" className='w-[30px] md:w-[45px]' />
            <div className='absolute bottom-1 -right-2'>
              <div className='bg-green-500 w-3 h-3 md:w-4 md:h-4 rounded-full  border-2 border-[#283342]'></div>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <h1 className='font-bold text-white text-md'>Jimma University Pharmacy</h1>
            
              {(isPremium !==false) ? (<h3 className='text-[#FED600] text-xs'>Premium Pharmacy </h3>):( <h3 className='text-[#FED600] text-xs'>Basic Pharmacy </h3>)
                
              }
             
          </div>

          <div className='font-bold cursor-pointer' onClick={handleShow}>
            <BsThreeDotsVertical color='white' size={20} />
          </div>
          {show && (
            <div className='fixed h-fit w-fit  bg-gray-200 rounded-lg shadow-lg z-[1000] top-10 right-12'>
              <div className='flex flex-col items-center justify-center p-3 cursor-pointer'>
              <button
            className='px-4 py-2 text-left text-gray-700 transition hover:bg-gray-100'
            onClick={() => {
              openModal();
              handleShow(); // Close dropdown when modal opens
            }}
          >
            Choose Plan
          </button>
          <button
            className='px-4 py-2 text-left text-[#F0483E] hover:bg-gray-100 transition'
            onClick={handleLogout}
          >
            Logout
          </button>
              </div>
            </div>
          )}
        </div>

        {/* Modal */}
        {modalIsOpen && (
         <>
         <div className="fixed inset-0 z-50 bg-black bg-opacity-50" onClick={closeModal}></div>
         <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
           <div className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-2xl">
             <h2 className="mb-6 text-3xl font-bold text-center text-gray-900">Select a Plan</h2>
             <div className="flex gap-6">
               {/* Basic Plan */}
               <div  className="w-1/2 p-6 transition-transform duration-300 transform border border-gray-300 rounded-lg bg-gray-50 hover:bg-gray-100 hover:-translate-y-1 hover:cursor-pointer hover:shadow-lg">
                 <h3 className="text-xl font-semibold text-gray-800">Basic Plan</h3>
                 <p className="mt-2 text-lg font-bold text-green-600">Free</p>
                 <div className="mt-4 space-y-2 text-sm text-gray-600">
                   <p>✔️ Limited Features</p>
                   <p>✔️ Competitive Price</p>
                   <p>✔️ Affordable Options</p>
                   <p>✔️ Nearby Pharmacy</p>
                   <p>✔️ Scanning Search</p>
                 </div>
               </div>
       
               {/* Premium Plan */}
               <div onClick={handleSubscribe} className="w-1/2 p-6 transition-transform duration-300 transform border border-gray-300 rounded-lg hover:cursor-pointer bg-gray-50 hover:bg-gray-100 hover:-translate-y-1 hover:shadow-lg">
                 <h3 className="text-xl font-semibold text-gray-800">Premium Plan</h3>
                 <p className="mt-2 text-lg font-bold text-green-600">3000 ETB/year</p>
                 <div className="mt-4 space-y-2 text-sm text-gray-600">
                   <p>✔️ All Basic Plan Features</p>
                   <p>✔️ Medicine Reminders</p>
                   <p>✔️ Advanced Medication Search Filters</p>
                   <p>✔️ Exclusive Discounts and Offers</p>
                   <p>✔️ Priority Support</p>
                 </div>
                 
               </div>
             </div>
             <button
               onClick={closeModal}
               className="absolute text-2xl text-gray-500 transition-colors duration-300 top-4 right-4 hover:text-gray-700"
             >
               &times;
             </button>
           </div>
         </div>
       </>
       
        )}
      </div>
    </div>
  );
};

export default Navbar;
