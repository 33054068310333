// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import InputText from '../../../shared/components/FormInputs/InputText'
import InputPassword from '../../../shared/components/FormInputs/InputPassword'
import InputButton from '../../../shared/components/FormInputs/InputButton'

const LoginForm = () => {
    const { register, handleSubmit } = useForm()

    // const navigate = useNavigate()

    // const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("AUTH_TOKEN") ? true : false)

    // const [isLoggedIn, setIsLoggedIn] = useState(false)



    const submitForm = async () => {
        alert("wrong credentials")
        // setIsLoggedIn(true)
        //     localStorage.setItem("isAuthenticated", true);
        //     console.log("dfgcjhv")
        // const loginData = {
        //     email: 'admin@gmail.com',
        //     pwd: '123'
        // }
        // try {

        //     if (data.email.match(loginData.email) && data.password.match(loginData.pwd)){

              
        //     }
        //     else {

        //         setIsLoggedIn(false)
        //     }






        //     /**
        //      * 
        //                 const response = await instance.post('/auth/login', {
        //                     email: data.email, password: data.password
            
        //                 })
            
        //                 if (response.statusText === 'OK') {
        //                     const { token } = response.data
        //                     localStorage.setItem('AUTH_TOKEN', token);
        //                     setIsLoggedIn(true)
            
        //                 }
        //      */

        //     // console.log(response)
        // } catch (error) {
        //     console.log(error.response.data.message)

        // }

    }

    // useEffect(() => {
    //     if (isLoggedIn) navigate('/dashboard')
    // }, [isLoggedIn])


    return (
        <div>
            <form onSubmit={handleSubmit(submitForm)} className="space-y-4 md:space-y-6">
                <InputText name='email' label="Email" type="email" placeholder="example@gmail.com" register={register} />

                <InputPassword name='password' label="Password" type="password" placeholder="••••••••" register={register} />

                <div className="flex items-center justify-between">
                    <Link to="/admin/auth/forgot" className="text-sm font-medium text-[#23826E] text-primary-600 hover:underline dark:text-primary-500">Forgot password?</Link>
                </div>
                <InputButton label="Sign in "     onClick={submitForm}/>
                <div className="flex items-center justify-center gap-1 text-black">
                    <span>Don't have an account? </span>
                    <Link to="/auth/register" className="text-sm font-medium text-[#23826E] text-primary-600 hover:underline dark:text-primary-500">Register</Link>
                </div>
            </form>
        </div>
    )
}
export default LoginForm