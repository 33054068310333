import React, { useEffect } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapContainers = ({ coordinates, setCoordinates }) => {
  const LocationPicker = () => {
    useMapEvents({
      click(e) {
        setCoordinates([e.latlng.lat, e.latlng.lng]);
      },
    });
    return null;
  };

  const UpdateMapView = ({ coordinates }) => {
    const map = useMap();
    useEffect(() => {
      map.setView(coordinates, map.getZoom());
    }, [coordinates, map]);
    return null;
  };

  return (
    <div className="w-full md:w-1/2 mt-6 md:mt-0 md:pl-6 flex flex-col justify-start">
      <div className="mt-14">
        <MapContainer
          center={coordinates}
          zoom={13}
          scrollWheelZoom={false}
          className="h-64"
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={coordinates} />
          <LocationPicker />
          <UpdateMapView coordinates={coordinates} />
        </MapContainer>
      </div>
    </div>
  );
};

export default MapContainers;
