import React from "react";
import SidebarHeader from "./SidebarHeader";
import SidebarSectionList from "./SidebarSection/SidebarSectionList";

import SidebarSection from "./SidebarSection/SidebarSection";
import {
  MdOutlineSpaceDashboard,
  MdOutlinePeopleAlt,
  MdNotificationsNone,
  MdOutlineSettings,
  MdMiscellaneousServices,
} from "react-icons/md";

const Sidebar = ({ handleSidebar, isActive }) => {
  const sectionList1 = [
    {
      value: "List of Medicine",
      path: "/list-of-medicine",
      isActive: false,
    },
    {
      value: "Medicine group",
      path: "/medicine-group",
      isActive: false,
    },
    {
      value: "Product List",
      path: "/inventory/product-list",
      isActive: false,
    },
  ];

  const sectionList2 = [
    {
      value: "Sales Report",
      path: "/sales-report",
      isActive: false,
    },
    {
      value: "payment Report",
      path: "/medicine-group",
      isActive: false,
    },
  ];

  return (
    <div
      className={`fixed flex flex-col top-0 left-0 no-scrollbar   h-vh hover:w-64 ${
        isActive ? "md:w-64 translate-x-0" : "w-0 -translate-x-full"
      }  bg-[#23826E]  h-full text-white transition-transform duration-300  border-none z-10 `}
    >
      <div className="flex flex-col justify-between flex-grow overflow-x-hidden overflow-y-auto no-scrollbar">
        <div className="  font-sans min-h-screen no-scrollbar  w-[256px] overflow-y-auto bg-[#23826E] ">
          <SidebarHeader handleSidebar={handleSidebar} />
          <hr className="w-full h-2 " />
          {/* <SidebarAccount /> */}
          <div className="text-sm leading-normal" style={{ fontWeight: "" }}>
            <div className="flex flex-col ">
              <SidebarSection
                icon={<MdOutlineSpaceDashboard />}
                value="Dashboard"
                path="/dashboard"
              />

              <SidebarSectionList
                value="Inventory"
                sectionList={sectionList1}
                path="/inventory"
              />
            </div>

            {/* <div className="bg-gray-500 h-[1px] my-2">

            </div> */}

            <div className="flex flex-col gap-3 ">
              <SidebarSection
                icon={<MdOutlinePeopleAlt />}
                value="National Medicine formulary (NMF)"
                path="/national-medicine-formulary"
              />

              <SidebarSectionList
                value="Report"
                sectionList={sectionList2}
                path="/report"
              />

              <SidebarSection
                icon={<MdOutlinePeopleAlt />}
                value="Configuration"
                path="/configuration"
              />
            </div>

            {/* <div className="bg-gray-500 h-[1px] my-2">

            </div> */}

            <div className="flex flex-col gap-3">
              <SidebarSection
                icon={<MdNotificationsNone />}
                value="Notification"
                path="/notification"
              />
              <SidebarSection
                icon={<MdOutlineSpaceDashboard />}
                value="Chat with Data Finder"
                path="/chat-with-dawa-finder"
              />
              <SidebarSection
                icon={<MdOutlineSettings />}
                value="Application Settings"
                path="/application-setting"
              />

              <SidebarSection
                icon={<MdMiscellaneousServices />}
                value="Terms of Service"
                path="/terms-of-service"
              />
            </div>
          </div>

          {/* <SidebarFooter /> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
