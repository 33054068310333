import { useEffect, useState } from 'react';
import './App.css'
import AppRoutes from './AppRoutes'
import Navbar from './shared/layout/NavBar/Navbar'

import Sidebar from './shared/layout/Sidebar/Sidebar';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
function App() {

  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [startWith, setStartWith] = useState(!location.pathname.startsWith('/auth'))
  const [sidebarActive, setSidebarActive] = useState(true); 
  const handleSidebar = () => {
    setSidebarActive(prev => !prev);
  };
  useEffect(() => {

    setStartWith(!location.pathname.startsWith('/auth'))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, startWith])


  return (
    <div className='font-openSans text-[#040a24] h-auto '>
      <div className="min-h-[80vh] h-auto overflow-hidden">
        <div className='flex bg-white'>
          {startWith && isAuthenticated && <Sidebar isActive={sidebarActive} handleSidebar={handleSidebar}/>}
          <div className='bg-gradient-to-r from-[#94b5ad]  via-45% via-[#b2c5c1] via-58% to-[#6cb5a5] to-97%
'>
            <div className={`${sidebarActive ? 'pl-14 md:pl-[256px]': 'pl-0' } px-8 transition-all duration-300  w-screen  overflow-auto`}>
           
            {startWith && isAuthenticated && <Navbar isActive={sidebarActive} handleSidebar={handleSidebar}/>}
            </div>
            <div className={` ${sidebarActive ? 'pl-14 md:pl-[256px]': 'pl-0' } transition-all duration-300 px-8 pt-4
 w-screen h-screen pr-[45px] overflow-auto`}>
              <AppRoutes />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default App
