import React from 'react'
import Main from '../InventoryItem/Create/Components/Main';

const CreateInventory = () => {
 
    return (
        <div className='pl-8 pb-4'>

            <Main />
        </div>
    );
};

export default CreateInventory
