import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import api from "../../../services/apiService";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loading: false,
    accessToken: Cookies.get("accessToken") || null,
    resetSuccessfull: null,
    profile: null,
    error: null,
    forgetError: null,
    forgotMessage: null,
    isAuthenticated:
      JSON.parse(localStorage.getItem("isAuthenticated")) || false,
    userInfo: null,
  },
  reducers: {
    clearAuthentication: (state) => {
      state.isAuthenticated = false;
      state.accessToken = null;
      localStorage.setItem("isAuthenticated", false);
      localStorage.removeItem("userInfo");
      Cookies.remove("accessToken");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.newUser;
        state.isAuthenticated = true;
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem(
          "userInfo",
          JSON.stringify(action.payload.newUser)
        );
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.accessToken = action.payload.token;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("userInfo", JSON.stringify(action.payload.user));
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.newHealthOrg;
        state.isAuthenticated = true;
        state.userInfo = action.payload.newHealthOrg;
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem(
          "userInfo",
          JSON.stringify(action.payload.newHealthOrg)
        ); 
        setAccessTokenInCookie(action.payload.token);
        state.error = null;
      })
      .addCase(createAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
        state.error = null;
      })
      .addCase(updateAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const setAccessTokenInCookie = (accessToken) => {
  Cookies.set("accessToken", accessToken, { secure: true, sameSite: "strict" });
};

export const register = createAsyncThunk(
  "auth/register",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "userapi/health-organizations/",
        userData
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createAccount = createAsyncThunk(
  "auth/createAccount",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await api.post("userapi/health-orgs/", userData);
      setAccessTokenInCookie(response.data.data.token);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAccount = createAsyncThunk(
  "auth/updateAccount",
  async (userData, { getState, rejectWithValue }) => {
    // Get the accessToken from the state
    

    try {
      console.log(userData)
      // Make the PATCH request with Authorization header
      const response = await api.patch(
        `userapi/health-orgs/${userData.id}`,
        {healthService:userData.healthService},
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await api.post("userapi/login/", userData);
      setAccessTokenInCookie(response.data.data.token);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = () => (dispatch) => {
  Cookies.remove("accessToken");
  dispatch(authSlice.actions.clearAuthentication());
};

export const fetchProfile = createAsyncThunk(
  "auth/fetchProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("userapi/health-organizations/profile");
      return response.data.profile;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export default authSlice.reducer;
