import React from 'react'
import SectionHeader from '../../../../../shared/components/SectionBody/SectionHeader'
import SectionSubHeader from '../../../../../shared/components/SectionBody/SectionSubHeader'

import Form from './Form'

const Main = () => {
    const header = 'Add products',
        subHeader = "You can add items by filling fields."

    return (
        <div>
            <div className=" flex justify-between items-center mb-8">
                <div className='flex flex-col gap-2 mt-4'>
                    <SectionHeader header={header} />
                    <SectionSubHeader subHeader={subHeader} />
                </div>
            </div>

            <Form />

        </div>
    )
}

export default Main