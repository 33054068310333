/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

const SidebarSection = ({ icon, value, path, }) => {

    // eslint-disable-next-line no-unused-vars
    const [activeLink, setActiveLink] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (location.pathname.includes(path)) {
            // console.log(true)
            setActiveLink(true)
        }
    }, [navigate])

    return (
        <div className={`pl-4   hover:bg-[#539284] py-[10px]`}>
            <Link to={path}>
                <div className='flex items-center justify-start gap-2 text-white '>
                    <span className='text-2xl md:text-lg'>{icon}</span>
                    <span className='ml-2 md:ml-0'>{value}</span>
                </div>

            </Link>
        </div>
    )
}

export default SidebarSection