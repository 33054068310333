import axios from "axios";
import Cookies from "js-cookie";
import { API_BASE_URL } from "../utils/Constants";

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const setupApiInterceptors = (store) => {
  api.interceptors.request.use(
    (config) => {
      // Safely access the access token from cookies
      const accessToken = Cookies.get("accessToken");

      // If the token exists, add it to the Authorization header
      if (accessToken) {
        console.log(accessToken)
        console.log("ni jiraaaaaaaaa")
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      console.log("hin jiru")
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        // Optionally handle token refresh here if needed
      }
      console.error("API Response Error:", error);
      return Promise.reject(error);
    }
  );
};

export default api;
