/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import InputText from "../../../shared/components/FormInputs/InputText";
import InputButton from "../../../shared/components/FormInputs/InputButton";
import {
  createAccount,
  register as registerOrg,
  updateAccount,
} from "../Slices/authSlice";

const steps = [
  "Create Account First",
  "Organization Details",
  "Location & License",
  "Contact & Images",
];

const RegisterForm = () => {
  const { register, handleSubmit, reset, getValues } = useForm();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.log("info", isAuthenticated, userInfo);
  const [step, setStep] = useState(1);
  const [accountCreated, setAccountCreated] = useState(false);
  const [images, setImages] = useState([]);

  const onSubmitAccountDetails = async (data) => {
    try {
      const res = await dispatch(createAccount(data)).unwrap();
      console.log(res);
      console.log("userinfoooo", userInfo);

      setAccountCreated(true);
      // alert(
      //   "Account created successfully! You can now proceed to fill in your organization details."
      // );
      setStep(step+1)
    } catch (error) {
      console.error("Account creation failed:", error);
    }
  };

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleNext = () => {
    if (step === 1 && !accountCreated) {
      alert("Please create your account before proceeding.");
      return;
    }
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleFinalSubmit = async () => {
    try {
      const data = getValues();

      const {
        coordinates,
        street,
        country,
        city,
        phoneNumber,
        email,
        serviceName,
        serviceDescription,
        ...rest
      } = data;

      const formattedData = {
        ...rest,
        absoluteLocation: { coordinates, street },
        relativeLocation: { country, city, street },
        contact: { phoneNumber, email },
        service: [{ serviceName, serviceDescription }],
        images: images.map((file) => URL.createObjectURL(file)),
      };

      const orgRes = await dispatch(registerOrg(formattedData)).unwrap();
      console.log("Organization registered successfully:", orgRes);

      if (userInfo) {
        const updateRes = await dispatch(
          updateAccount({
            id: userInfo._id,
            healthService: orgRes.newHealthOrgService._id,
          })
        ).unwrap();
        console.log("Account updated successfully:", updateRes);

        // dispatch({ type: "auth/updateUserInfo", payload: updateRes });
      }

      // alert("All details submitted successfully!");

      // reset();
      setStep(1); // Go back to the first step or navigate away
    } catch (error) {
      console.error("Submission failed:", error);
    }
  };

  const errorMessage = error ? JSON.stringify(error) : null;

  return (
    <div className="w-full p-6 space-y-6 bg-white shadow-lg rounded-xl">
      <h1 className="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-3xl">
        Welcome to Dawa Finder ERP👋
      </h1>

      <h1 className="text-sm font-normal tracking-tight text-center text-gray-900 md:text-xl">
        Kindly fill in your details below to register your pharmacy
      </h1>

      {/* Stepper */}
      <div className="flex items-center justify-between mb-6">
        {steps.map((label, index) => (
          <div
            key={index}
            className="flex items-center cursor-pointer"
            onClick={() => setStep(index + 1)}
          >
            <div
              className={`w-8 h-8 flex items-center justify-center rounded-full text-white ${
                step === index + 1
                  ? "bg-[#83a8a0]"
                  : step > index + 1
                  ? "bg-[#23826E]"
                  : "bg-gray-300"
              }`}
            >
              {step > index + 1 ? "✓" : index + 1}
            </div>
            <div
              className={`ml-2 text-sm ${
                step === index + 1 ? "text-blue-500" : "text-gray-500"
              }`}
            >
              {label}
            </div>
            {index < steps.length - 1 && (
              <div
                className={`flex-1 h-0.5 mx-2 ${
                  step > index + 1 ? "bg-green-500" : "bg-gray-300"
                }`}
              ></div>
            )}
          </div>
        ))}
      </div>

      {/* Step 1: Account Details */}
      {step === 1 && (
        <div className="space-y-4">
          <InputText
            name="firstName"
            label="First Name"
            type="text"
            placeholder="Enter first name"
            register={register}
            required
          />
          <InputText
            name="lastName"
            label="Last Name"
            type="text"
            placeholder="Enter last name"
            register={register}
            required
          />
          <InputText
            name="phoneNumber"
            label="Phone Number"
            type="tel"
            placeholder="Enter phone number"
            register={register}
            required
          />
          <InputText
            name="email"
            label="Email"
            type="email"
            placeholder="Enter email"
            register={register}
            required
          />
          <InputText
            name="password"
            label="Password"
            type="password"
            placeholder="Enter password"
            register={register}
            required
          />
          <InputText
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            placeholder="Confirm password"
            register={register}
            required
          />
          <InputButton
            label="Create Account"
            onClick={() => handleSubmit(onSubmitAccountDetails)()}
            disabled={loading}
          />
        </div>
      )}

      {/* Step 2: Organization Details */}
      {step === 2 && (
        <div className="space-y-4">
          <InputText
            name="name"
            label="Organization Name"
            type="text"
            placeholder="Enter name"
            register={register}
            required
          />
          <InputText
            name="subscription"
            label="Subscription"
            type="text"
            placeholder="Enter subscription"
            register={register}
            required
          />
          <InputText
            name="description"
            label="Description"
            type="text"
            placeholder="Enter description"
            register={register}
            required
          />
          <div className="flex justify-between">
            <InputButton
              label="Previous"
              onClick={handlePrevious}
              disabled={step === 1}
            />
            <InputButton label="Next" onClick={handleNext} />
          </div>
        </div>
      )}

      {/* Step 3: Location & License */}
      {step === 3 && (
        <div className="space-y-4">
          <InputText
            name="coordinates"
            label="Coordinates"
            type="text"
            placeholder="Enter coordinates"
            register={register}
            required
          />
          <InputText
            name="street"
            label="Street"
            type="text"
            placeholder="Enter street"
            register={register}
            required
          />
          <InputText
            name="country"
            label="Country"
            type="text"
            placeholder="Enter country"
            register={register}
            required
          />
          <InputText
            name="city"
            label="City"
            type="text"
            placeholder="Enter city"
            register={register}
            required
          />
          <InputText
            name="license"
            label="License Number"
            type="text"
            placeholder="Enter license number"
            register={register}
            required
          />
          <InputText
            name="type"
            label="Organization Type"
            type="text"
            placeholder="Enter organization type"
            register={register}
            required
          />
          <div className="flex justify-between">
            <InputButton
              label="Previous"
              onClick={handlePrevious}
              disabled={step === 1}
            />
            <InputButton label="Next" onClick={handleNext} />
          </div>
        </div>
      )}

      {/* Step 4: Contact & Images */}
      {step === 4 && (
        <div className="space-y-4">
          <InputText
            name="phoneNumber"
            label="Phone Number"
            type="text"
            placeholder="Enter phone number"
            register={register}
            required
          />
          <InputText
            name="email"
            label="Email"
            type="email"
            placeholder="Enter email"
            register={register}
            required
          />
          <InputText
            name="serviceName"
            label="Service Name"
            type="text"
            placeholder="Enter service name"
            register={register}
            required
          />
          <InputText
            name="serviceDescription"
            label="Service Description"
            type="text"
            placeholder="Enter service description"
            register={register}
            required
          />
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleImageChange}
          />
          <div className="flex justify-between">
            <InputButton
              label="Previous"
              onClick={handlePrevious}
              disabled={step === 1}
            />
            <InputButton
              label="Submit"
              onClick={handleFinalSubmit}
              disabled={loading}
            />
          </div>
        </div>
      )}

      {/* Error message */}
      {errorMessage && <div className="text-red-500 mt-4">{errorMessage}</div>}
    </div>
  );
};

export default RegisterForm;
